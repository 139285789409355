import { adminApi } from "@/api";

export default {
  state: () => ({
    feedbacks: [],
    feedbacksCount: 0
  }),
  actions: {
    fetchFeedbacks({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/feedbacks", { params })
          .then(response => {
            commit("setFeedbacks", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchFeedbacksCount({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/feedbacks/count", { params })
          .then(response => {
            commit("setFeedbacksCount", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteFeedback({ commit }, id) {
      return new Promise((resolve, reject) => {
        adminApi
          .delete(`/feedbacks/${id}`)
          .then(response => {
            commit("deleteFeedback", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setFeedbacks(state, feedbacks) {
      state.feedbacks = feedbacks;
    },
    setFeedbacksCount(state, count) {
      state.feedbacksCount = count;
    },
    deleteFeedback(state, feedback) {
      state.feedbacks = state.feedbacks.filter(f => f.id !== feedback.id);
      state.feedbacksCount--;
    }
  }
};
