<template>
  <fullscreen ref="fullscreen" @change="fullscreenChange">
    <div class="default-layout">
      <div class="sidebar">
        <el-menu class="el-menu-vertical-demo">
          <router-link
            active-class="is-active"
            index="1"
            tag="el-menu-item"
            to="/"
            class="logo-menu-item"
          >
            <img src="../assets/images/logo.png" class="logo-img" alt="Logo" />
          </router-link>
          <router-link
            active-class="is-active"
            index="2"
            exact
            tag="el-menu-item"
            to="/"
          >
            <i class="el-icon-s-home"></i>
            <span>Dashboard</span>
          </router-link>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Ресурслар</span>
            </template>
            <el-menu-item-group title="Ресурслар">
              <router-link
                active-class="is-active"
                tag="el-menu-item"
                to="/resources"
                exact
                index="3-1"
              >
                <i class="el-icon-notebook-2"></i>
                <span>Барча ресурслар</span>
              </router-link>
              <router-link
                active-class="is-active"
                tag="el-menu-item"
                to="/resources/new"
                index="3-2"
              >
                <i class="el-icon-document-add"></i>
                <span>Ресурс кушиш</span>
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <router-link
            active-class="is-active"
            index="2"
            tag="el-menu-item"
            to="/videos"
          >
            <i class="el-icon-video-camera"></i>
            <span>Видеолар</span>
          </router-link>
          <router-link
            active-class="is-active"
            index="2"
            tag="el-menu-item"
            to="/categories"
          >
            <i class="el-icon-files"></i>
            <span>Категориялар</span>
          </router-link>
          <router-link
            active-class="is-active"
            index="2"
            tag="el-menu-item"
            to="/archivenewspaper"
          >
            <i class="el-icon-receiving"></i>
            <span>"Postda" газеталар</span>
          </router-link>
          <router-link
            active-class="is-active"
            index="2"
            tag="el-menu-item"
            to="/about-edit"
          >
            <i class="el-icon-info"></i>
            <span>"Биз ҳақимизда" сахифаси</span>
          </router-link>
          <router-link
            active-class="is-active"
            index="2"
            tag="el-menu-item"
            to="/leadership"
          >
            <i class="el-icon-s-custom"></i>
            <span>Рахбарият</span>
          </router-link>
          <router-link
            active-class="is-active"
            index="2"
            tag="el-menu-item"
            to="/feedbacks"
          >
            <i class="el-icon-message"></i>
            <span>Мурожаатлар</span>
          </router-link>
          <router-link
            active-class="is-active"
            index="2"
            tag="el-menu-item"
            to="/contacts"
          >
            <i class="el-icon-chat-line-round"></i>
            <span>Алоқа</span>
          </router-link>
        </el-menu>
      </div>
      <div class="content">
        <header class="header">
          <div class="header-title">
            Postda - админ панель
          </div>

          <div class="wrap">
            <el-button
              @click="toggle"
              class="fullscreen-btn"
              icon="el-icon-full-screen"
            />
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                <div class="profile">
                  <div class="profile-ava">
                    <img src="../assets/images/ava.png" alt="Ava" />
                  </div>
                  <div class="profile-name">{{ username || "-" }}</div>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-back" command="logout">
                  Logout
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </header>
        <div class="content-inner">
          <router-view />
        </div>
        <footer class="footer">
          <div class="copyright">
            Copyright © 2021 <a href="#">JSD Web Security</a>
            All rights reserved
          </div>
        </footer>
      </div>
    </div>
  </fullscreen>
</template>

<script>
export default {
  data: () => ({
    fullscreen: false
  }),
  computed: {
    username() {
      return (
        this.$store.state.authModule.userData.username ||
        this.$store.state.authModule.userData.email
      );
    }
  },
  methods: {
    handleCommand(command) {
      if (command === "logout") {
        this.$store.commit("logout");
      }
    },
    toggle() {
      this.$refs["fullscreen"].toggle();
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    }
  }
};
</script>

<style lang="scss">
.fullscreen-btn {
  margin-right: 10px;
}
.wrap {
  display: flex;
  align-items: center;
}
.header-title {
  font-size: 22px;
}
.profile {
  display: flex;
  align-items: center;
  padding: 10px;
  transition: 0.2s all;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background: #ecf5ff;
    color: #409eff;
  }
  &-name {
    flex: 1;
  }
  &-name {
    margin-left: 5px;
  }
  &-ava {
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.default-layout {
  display: flex;
  align-items: stretch;
  min-height: 100vh;
}

.copyright {
  font-size: 14px;
  a {
    color: #409eff;
    text-decoration: none;
  }
}

.logo-img {
  max-width: 100%;
  height: auto;
}

.logo-menu-item {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sidebar {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  .el-menu-vertical-demo {
    width: 260px;
  }
  .el-menu-item-group__title {
    display: none;
  }
}
.content {
  background: #ecf0fa;
  width: calc(100% - 260px);
  display: flex;
  flex-direction: column;
  &-inner {
    flex: 1;
  }
}

.header {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  padding: 15px 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.footer {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
