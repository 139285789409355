import { adminApi } from "@/api";

export default {
  state: () => ({
    categories: [],
    categoriesCount: 0
  }),
  actions: {
    fetchCategories({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/categories", {
            params: {
              ...params,
              "": new Date().getTime()
            }
          })
          .then(response => {
            commit("setCategories", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchCategoriesCount({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/categories/count", { params })
          .then(response => {
            commit("setCategoriesCount", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addCategory({ commit }, data) {
      return new Promise((resolve, reject) => {
        adminApi
          .post("/categories", data)
          .then(response => {
            commit("addCategory", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteCategory({ commit }, catId) {
      return new Promise((resolve, reject) => {
        adminApi
          .delete(`/categories/${catId}`)
          .then(response => {
            commit("deleteCategory", catId);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editCategory({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        adminApi
          .put(`/categories/${id}`, data)
          .then(response => {
            commit("editCategory", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setCategoriesCount(state, count) {
      state.categoriesCount = count;
    },
    addCategory(state, category) {
      state.categories.push(category);
    },
    deleteCategory(state, catId) {
      state.categories = state.categories.filter(cat => cat.id !== catId);
    },
    editCategory(state, cat) {
      state.categories = state.categories.map(c => (c.id !== cat.id ? c : cat));
    }
  }
};
