import { adminApi } from "@/api";

export default {
  state: () => ({
    aboutUs: null
  }),
  actions: {
    getAboutUs({ commit }) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/about-us")
          .then(response => {
            commit("setAboutUs", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateAboutUs({ commit }, data) {
      return new Promise((resolve, reject) => {
        adminApi
          .put("about-us", data)
          .then(response => {
            commit("setAboutUs", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setAboutUs(state, aboutUs) {
      state.aboutUs = aboutUs;
    }
  }
};
