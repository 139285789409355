import axios from "axios";
import config from "@/config";
import store from "@/store";

export const adminApi = axios.create({
  baseURL: config.baseUrl,
  params: {
    "": new Date().getTime()
  },
  validateStatus: function(status) {
    if (status === 403 || status === 401) return store.commit("logout");
    return status >= 200 && status < 300;
  }
});
