import { adminApi } from "@/api";
import router from "@/router";

export default {
  state: () => ({
    userData: null,
    isLoggedIn: false
  }),
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        adminApi
          .post("/auth/local", data)
          .then(response => {
            commit("loggedIn", response.data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    logout(state) {
      state.loggedIn = false;
      state.userData = null;
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      delete adminApi.defaults.headers.common["Authorization"];
      router.push("/login");
    },
    loggedIn(state, data) {
      state.loggedIn = true;
      state.userData = data.user;
      adminApi.defaults.headers.common["Authorization"] = "Bearer " + data.jwt;
      localStorage.setItem("token", data.jwt);
      localStorage.setItem("userData", JSON.stringify(data.user));
    },
    setLogin(state) {
      const token = localStorage.getItem("token");
      const userData = localStorage.getItem("userData");

      if (token && userData) {
        state.loggedIn = true;
        state.userData = JSON.parse(userData);
        adminApi.defaults.headers.common["Authorization"] = "Bearer " + token;
      }
    }
  }
};
