<template>
  <div class="dashboard fade-up">
    <el-row :gutter="30">
      <el-col :span="12">
        <el-card class="card card--success" shadow="hover">
          <div class="card__inner">
            <i class="card__icon el-icon-notebook-2"></i>
            <div class="card__content">
              <count-up
                class="card__count"
                :value="publishedCount"
                :duration="800"
              />
              <div class="card__name">Нашр қилинган ресурслар</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="card card--warning" shadow="hover">
          <div class="card__inner">
            <i class="card__icon el-icon-date"></i>
            <div class="card__content">
              <count-up
                class="card__count"
                :value="draftCount"
                :duration="800"
              />
              <div class="card__name">Режалаштирилган ресурслар</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <div class="dashboard-table" v-loading="isLoading">
      <div class="dashboard-table__header">
        <div
          class="dashboard-table__header-actions"
          :class="{ visible: multipleSelection.length }"
        >
          <div class="selected">{{ multipleSelection.length }} танланган</div>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deleteSelectedResource"
          >
            Ўчириш
          </el-button>
        </div>
        <div class="left">
          <el-input
            placeholder="Номи буйича излаш"
            prefix-icon="el-icon-search"
            v-model="filter.search"
          />
        </div>
        <div class="right">
          <el-select
            v-model="filter.selectedCategory"
            placeholder="Категория"
            style="margin-right: 15px;"
          >
            <el-option
              v-for="cat in categories"
              :key="cat.id"
              :label="cat.name_uz"
              :value="cat.slug"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="$router.push('/resources/new')"
          >
            Янги қушиш
          </el-button>
        </div>
      </div>
      <div class="dashboard-table__body">
        <el-table
          ref="dashboardTable"
          style="width: 100%"
          :data="resourcesData"
          @selection-change="handleSelectionChange"
          @cell-click="toPreview"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="publish_at"
            label="Нашр вақти"
            width="150"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.publish_at).format("HH:mm | DD.MM.YYYY") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="updated_at"
            label="Тахрирлаш вақти"
            width="180"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.updated_at).format("HH:mm | DD.MM.YYYY") }}
            </template>
          </el-table-column>
          <el-table-column prop="title_uz" label="Номи"></el-table-column>
          <el-table-column prop="description_uz" label="Қисқача">
          </el-table-column>
          <el-table-column prop="status" label="Статус" width="100">
            <template slot-scope="scope">
              <el-tag
                size="medium"
                :type="scope.row.status === 'published' ? 'success' : 'warning'"
              >
                {{ scope.row.status }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Амаллар" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click.stop="$router.push('/resources/edit/' + scope.row.id)"
              />
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click.stop="deleteResource(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dashboard-table__footer">
        <el-pagination
          :disabled="isLoading"
          background
          layout="prev, pager, next"
          :page-size="perPage"
          :total="totalCount"
          :current-page.sync="currentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import CountUp from "@/components/CountUp";

export default {
  components: {
    CountUp
  },
  data: () => ({
    draftCount: 0,
    publishedCount: 0,
    perPage: 8,
    currentPage: 1,
    isLoading: false,
    filter: {
      selectedCategory: "",
      search: ""
    },
    multipleSelection: []
  }),
  watch: {
    currentPage() {
      if (this.isLoading) return;

      this.fetchNews();
    },
    filter: {
      deep: true,
      handler() {
        this.isLoading = true;
        this.currentPage = 1;
        this.fetchNews();
      }
    }
  },
  computed: {
    totalCount() {
      return this.$store.state.resourceModule.resourcesCount;
    },
    resourcesData() {
      return this.$store.state.resourceModule.resources;
    },
    categories() {
      return this.$store.state.categoryModule.categories;
    }
  },
  created() {
    const loading = this.$loading({
      lock: true
    });
    Promise.all([
      this.$store.dispatch("fetchResources", {
        _start: this.currentPage * this.perPage - this.perPage,
        _limit: this.perPage
      }),
      this.$store.dispatch("fetchCategories"),
      this.$store.dispatch("fetchResourcesCount", { status: "published" }),
      this.$store.dispatch("fetchResourcesCount", { status: "draft" }),
      this.$store.dispatch("fetchResourcesCount")
    ])
      .then(([resources, categories, publishedCount, draftCount]) => {
        console.log(resources, "resources");
        console.log(categories, "categories");
        this.publishedCount = +publishedCount;
        this.draftCount = +draftCount;
      })
      .finally(() => {
        loading.close();
      });
  },
  methods: {
    fetchNews() {
      this.isLoading = true;
      const params = {};
      if (this.filter.search) {
        params.title_uz_contains = this.filter.search;
      } else {
        delete params.title_uz_contains;
      }
      if (this.filter.selectedCategory.length > 0) {
        params["category.slug"] = this.filter.selectedCategory;
      } else {
        delete params["category.slug"];
      }
      Promise.all([
        this.$store.dispatch("fetchResourcesCount", params),
        this.$store.dispatch("fetchResources", {
          _start: this.currentPage * this.perPage - this.perPage,
          _limit: this.perPage,
          ...params
        })
      ]).finally(() => {
        this.isLoading = false;
      });
    },
    toPreview(row) {
      this.$router.push("/resources/" + row.id);
    },
    deleteResource(row) {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          this.$store
            .dispatch("deleteResource", row.id)
            .then(() => {
              if (row.status === "published") {
                this.publishedCount--;
              } else {
                this.draftCount--;
              }
              if (this.resourcesData.length === 0) {
                this.isLoading = false;
                this.fetchNews();
              }
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    deleteSelectedResource() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          Promise.all(
            this.multipleSelection.map(r => {
              this.$store.dispatch("deleteResource", r.id);
            })
          )
            .then(() => {
              this.draftCount =
                this.draftCount -
                this.multipleSelection.filter(r => r.status === "draft").length;
              this.publishedCount =
                this.publishedCount -
                this.multipleSelection.filter(r => r.status === "published")
                  .length;
              if (this.resourcesData.length === 0) {
                this.isLoading = false;
                this.fetchNews();
              }
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 20px;
}

.card {
  border-radius: 10px;
  color: #909399;
  &__inner {
    display: flex;
    align-items: center;
    transition: 0.2s all;
  }

  &--success {
    color: #67c23a;
    // background-color: #f0f9eb;
    // border: 1px solid #e1f3d8;
  }

  &--warning {
    color: #e6a23c;
    // background-color: #fdf6ec;
    // border: 1px solid #faecd8;
  }

  &:hover {
    .card__inner {
      color: #409eff;
    }
  }

  &__icon {
    font-size: 86px;
    font-weight: bold;
    margin-right: 25px;
    line-height: 1.2;
  }

  &__content {
    line-height: 1.1;
  }

  &__name {
    font-size: 14px;
    margin-top: 4px;
  }

  &__count {
    font-size: 56px;
    font-weight: bold;
  }
}
</style>
