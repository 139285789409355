import { adminApi } from "@/api";

export default {
  state: () => ({
    videos: [],
    videosCount: 0,
    singleVideo: null
  }),
  actions: {
    fetchVideos({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/videos", {
            params: {
              _sort: "published_at:desc",
              ...params
            }
          })
          .then(response => {
            commit("setVideos", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchSingleVideo({ commit }, id) {
      return new Promise((resolve, reject) => {
        adminApi
          .get(`/videos/${id}`, {
            params: {
              "": new Date().getTime()
            }
          })
          .then(response => {
            commit("setSingleVideo", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchVideosCount({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/videos/count", { params })
          .then(response => {
            commit("setVideosCount", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteVideo({ commit }, videoId) {
      return new Promise((resolve, reject) => {
        adminApi
          .delete(`/videos/${videoId}`)
          .then(response => {
            commit("deleteVideo", videoId);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addVideo({ commit }, data) {
      return new Promise((resolve, reject) => {
        adminApi
          .post("/videos", data)
          .then(response => {
            commit("addVideo", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateVideo(_, { data, id }) {
      return new Promise((resolve, reject) => {
        adminApi
          .put(`/videos/${id}`, data)
          .then(response => {
            // commit("updateVideo", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setVideos(state, videos) {
      state.videos = videos;
    },
    setVideosCount(state, count) {
      state.videosCount = count;
    },
    deleteVideo(state, rId) {
      state.videos = state.videos.filter(r => r.id !== rId);
      state.videosCount--;
    },
    addVideo(state, video) {
      state.videos.push(video);
      state.videosCount++;
    },
    setSingleVideo(state, video) {
      state.singleVideo = video;
    }
  }
};
