import { adminApi } from "@/api";

export default {
  state: () => ({
    leaders: []
  }),
  actions: {
    fetchLeaders({ commit }) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/leaders")
          .then(response => {
            commit("setLeaders", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addLeader({ commit }, data) {
      return new Promise((resolve, reject) => {
        adminApi
          .post("/leaders", data)
          .then(response => {
            commit("addLeader", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateLeader({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        adminApi
          .put(`/leaders/${id}`, data)
          .then(response => {
            commit("updateLeader", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteLeader({ commit }, id) {
      return new Promise((resolve, reject) => {
        adminApi
          .delete(`/leaders/${id}`)
          .then(response => {
            commit("deleteLeader", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setLeaders(state, leaders) {
      state.leaders = leaders;
    },
    addLeader(state, leader) {
      state.leaders.push(leader);
    },
    updateLeader(state, leader) {
      state.leaders = state.leaders.map(l => (l.id !== leader.id ? l : leader));
    },
    deleteLeader(state, leader) {
      state.leaders = state.leaders.filter(l => l.id !== leader.id);
    }
  }
};
