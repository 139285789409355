import { adminApi } from "@/api";

export default {
  state: () => ({
    newspapers: []
  }),
  actions: {
    fetchNewspapers({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/newspapers", { params })
          .then(response => {
            commit("setNewspapers", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addNewspaper({ commit }, data) {
      return new Promise((resolve, reject) => {
        adminApi
          .post("/newspapers", data)
          .then(response => {
            commit("addNewspaper", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateNewspaper({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        adminApi
          .put(`/newspapers/${id}`, data)
          .then(response => {
            commit("updateNewspaper", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteNewspaper({ commit }, id) {
      return new Promise((resolve, reject) => {
        adminApi
          .delete(`/newspapers/${id}`)
          .then(response => {
            commit("deleteNewspaper", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setNewspapers(state, newspapers) {
      state.newspapers = newspapers;
    },
    addNewspaper(state, newspaper) {
      state.newspapers.push(newspaper);
    },
    updateNewspaper(state, newspaper) {
      state.newspapers = state.newspapers.map(l =>
        l.id !== newspaper.id ? l : newspaper
      );
    },
    deleteNewspaper(state, newspaper) {
      state.newspapers = state.newspapers.filter(l => l.id !== newspaper.id);
    }
  }
};
