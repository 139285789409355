import { adminApi } from "@/api";

export default {
  state: () => ({
    contacts: [],
    contactsCount: 0
  }),
  actions: {
    fetchContacts({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/contacts", { params })
          .then(response => {
            commit("setContacts", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchContactsCount({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/contacts/count", { params })
          .then(response => {
            commit("setContactsCount", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteContact({ commit }, id) {
      return new Promise((resolve, reject) => {
        adminApi
          .delete(`/contacts/${id}`)
          .then(response => {
            commit("deleteContact", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setContacts(state, contacts) {
      state.contacts = contacts;
    },
    setContactsCount(state, count) {
      state.contactsCount = count;
    },
    deleteContact(state, contact) {
      state.contacts = state.contacts.filter(f => f.id !== contact.id);
      state.contactsCount--;
    }
  }
};
