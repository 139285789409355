import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";

Vue.use(VueRouter);

function protectedEnter(to, from, next) {
  var hasPermission = Boolean(localStorage.getItem("token"));
  if (hasPermission) {
    next();
  } else {
    next({
      name: "Login"
    });
  }
}

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/videos",
    name: "Videos",
    component: () => import("@/views/Videos.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/videos/new",
    name: "New Video",
    component: () => import("@/views/NewVideo.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/videos/edit/:id",
    name: "Edit Video",
    component: () => import("@/views/EditVideo.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/resources",
    name: "Resources",
    component: () => import("@/views/Resources.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/resources/new",
    name: "New Resource",
    component: () => import("@/views/NewResource.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/resources/edit/:id",
    name: "Edit Resource",
    component: () => import("@/views/EditResource.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/resources/:id",
    name: "Single Resource",
    component: () => import("@/views/SingleResource.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/about-edit",
    name: "About edit",
    component: () => import("@/views/AboutEdit.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/leadership",
    name: "Leadership",
    component: () => import("@/views/Leadership.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/feedbacks",
    name: "Feedbacks",
    component: () => import("@/views/Feedbacks.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("@/views/Contacts.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("@/views/Categories.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/archivenewspaper",
    name: "ArchiveNewspaper",
    component: () => import("@/views/ArchiveNewspaper.vue"),
    meta: {
      layout: "default"
    },
    beforeEnter: protectedEnter
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter(to, from, next) {
      var hasPermission = Boolean(localStorage.getItem("token"));
      if (!hasPermission) {
        next();
      } else {
        next({
          name: "Dashboard"
        });
      }
    }
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
