<template>
  <div class="count-up">{{ count }}</div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 500
    }
  },
  data: () => ({
    count: 0
  }),
  watch: {
    value(val) {
      this.count = val;
    }
  },
  mounted() {
    const countInterval = setInterval(() => {
      this.count >= this.value ? clearInterval(countInterval) : this.count++;
    }, this.duration / this.value);
  }
};
</script>
