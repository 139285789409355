<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import DefaultLayout from "@/layouts/DefaultLayout";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    }
  },
  beforeCreate() {
    this.$store.commit("setLogin");
  },
  components: {
    EmptyLayout,
    DefaultLayout
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fadeUp 0.4s ease-in;
}

.fade-down {
  animation: fadeDown 0.4s ease-in;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.dashboard-table .cell {
  white-space: nowrap;
}

.el-table__row {
  cursor: pointer;
}

.invalid-error {
  color: red;
  input {
    border-color: red;
  }
}

.dialog-sm {
  .el-dialog__body {
    padding: 10px 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}

.expanded {
  p:not(:last-child) {
    margin-bottom: 10px;
  }
}

a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: #409eff;
  transition: 0.2s color;
  &:hover,
  &:focus {
    color: darken(#4093ff, 20);
  }
}

.dashboard-table {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  &__header {
    padding: 15px;
    display: flex;
    border-bottom: solid 1px #e6e6e6;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &-actions {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 2;
      background: lighten(#409eff, 10);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      color: #fff;
      transition: 0.4s all;
      visibility: hidden;
      opacity: 0;
      &.visible {
        visibility: visible;
        opacity: 1;
      }
      .selected {
        font-size: 14px;
        line-height: 1.2;
        font-weight: bold;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);
    margin: 0 auto;
  }
  &__footer {
    padding: 20px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.el-textarea__inner {
  min-height: 150px !important;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  min-height: 150px;
}
.el-dialog {
  width: 35% !important;
}
</style>
