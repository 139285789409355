import { adminApi } from "@/api";

import Vue from "vue";
import Vuex from "vuex";

import authModule from "./authModule";
import resourceModule from "./resourceModule";
import categoryModule from "./categoryModule";
import pagesModule from "./pagesModule";
import leaderModule from "./leaderModule";
import feedbackModule from "./feedbackModule";
import contactModule from "./contactModule";
import newspaperModule from "./newspaperModule";
import videoModule from "./videoModule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    uploadFile(_, data) {
      return new Promise((resolve, reject) => {
        adminApi
          .post("/upload", data)
          .then(response => resolve(response.data))
          .catch(error => reject(error));
      });
    }
  },
  modules: {
    authModule,
    resourceModule,
    categoryModule,
    pagesModule,
    leaderModule,
    feedbackModule,
    contactModule,
    newspaperModule,
    videoModule
  }
});
