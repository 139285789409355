<template>
  <div class="login-page">
    <el-form
      :model="form"
      :rules="rules"
      ref="loginForm"
      label-width="100px"
      label-position="top"
      class="demo-ruleForm fade-up"
    >
      <el-card class="login-box">
        <div slot="header" class="login-box__footer">
          <h2 class="title">Кириш</h2>
          <el-form-item prop="identifier">
            <el-input placeholder="Username" v-model="form.identifier">
              <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              placeholder="Password"
              type="password"
              v-model="form.password"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
        </div>
        <el-button
          @click="login('loginForm')"
          class="submit-btn"
          type="primary"
          :loading="isSending"
        >
          Кириш
        </el-button>
      </el-card>
    </el-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    form: {
      identifier: "",
      password: ""
    },
    rules: {
      identifier: [
        {
          required: true,
          message: "Полени тулдиринг!",
          trigger: "blur"
        }
      ],
      password: [
        {
          required: true,
          message: "Полени тулдиринг!",
          trigger: "blur"
        }
      ]
    },
    isSending: false
  }),
  methods: {
    login(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.isSending) return false;
          this.isSending = true;
          this.$store
            .dispatch("login", this.form)
            .then(() => {
              this.$router.push("/");
            })
            .catch(() => {
              this.$notify.error({
                title: "Диққат!",
                message: "Логин йоки парол хато!"
              });
            })
            .finally(() => (this.isSending = false));
        } else {
          this.$notify.error({
            title: "Диққат!",
            message: "Формани тўлдиринг!"
          });

          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
  margin-top: 10px;
}
.input {
  margin-bottom: 10px;
}
.login-box {
  width: 400px;
}
.submit-btn {
  width: 100%;
}
.login-page {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #ecf0fa;
}
</style>
