import { adminApi } from "@/api";

export default {
  state: () => ({
    resources: [],
    resourcesCount: 0,
    singleResource: null
  }),
  actions: {
    fetchResources({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/articles", {
            params: {
              _sort: "published_at:desc",
              ...params
            }
          })
          .then(response => {
            commit("setResources", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchSingleResource({ commit }, id) {
      return new Promise((resolve, reject) => {
        adminApi
          .get(`/articles/${id}`, {
            params: {
              timestamp: new Date().getTime()
            }
          })
          .then(response => {
            commit("setSingleResource", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchResourcesCount({ commit }, params) {
      return new Promise((resolve, reject) => {
        adminApi
          .get("/articles/count", { params })
          .then(response => {
            commit("setResourcesCount", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteResource({ commit }, resourceId) {
      return new Promise((resolve, reject) => {
        adminApi
          .delete(`/articles/${resourceId}`)
          .then(response => {
            commit("deleteResource", resourceId);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addResource({ commit }, data) {
      return new Promise((resolve, reject) => {
        adminApi
          .post("/articles", data)
          .then(response => {
            commit("addResource", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateResource(_, { data, id }) {
      return new Promise((resolve, reject) => {
        adminApi
          .put(`/articles/${id}`, data)
          .then(response => {
            // commit("updateResource", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setResources(state, resources) {
      state.resources = resources;
    },
    setResourcesCount(state, count) {
      state.resourcesCount = count;
    },
    deleteResource(state, rId) {
      state.resources = state.resources.filter(r => r.id !== rId);
      state.resourcesCount--;
    },
    addResource(state, resource) {
      state.resources.push(resource);
      state.resourcesCount++;
    },
    setSingleResource(state, resource) {
      state.singleResource = resource;
    }
  }
};
